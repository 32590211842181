.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  margin: 0px;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  align-items: center;
  display: grid;
  gap: 20px;
  z-index: 2;
}
.popup-close {
  position: absolute;
  top: 1px;
  right: 5px;
  content: "x";
  cursor: pointer;
}
.subscribePopup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subscribePopup h4 {
  font-size: 1rem;
  margin: 0.5rem 0 0.1rem 0;
}
.subscribePopup p {
  font-size: 0.9rem;
  margin: 0.6rem 0 0.8rem 0;
}
.subscribePopup .email {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  display: flex;
  position: relative;
  box-sizing: border-box;
  outline: none;
}
.subscribePopup button {
  width: 100%;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
