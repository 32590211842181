@import url(https://db.onlinewebfonts.com/c/d81ca340fb94ceeae147d9f666039709?family=Contax+W01+65+Medium);
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media only screen and (max-width: 600px) {
  .shop-item-image {
    width: 100%;
  }
  .shop {
    gap: 5px;
    grid-template-columns: 1fr 1fr !important;
  }
}
* {
  font-family: "Karla", sans-serif;
  box-sizing: border-box;
}
body {
  margin: 0;
}
.logo {
  font-family: "Contax W01 65 Medium";
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px;
  font-weight: 1000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: white;
  font-size: 50px;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  transition: all 0.1s ease-out;
  z-index: 99;
}

.logo.scrolled {
  font-size: 30px;
}
.shop-name {
  font-family: "Contax W01 65 Medium";
  font-weight: bold;
  font-size: 25px;
  padding: 30px;
  display: inline-block;
}
.shop-description {
  margin: -20px 30px 20px 30px;
  max-width: 80%;
  font-style: italic;
}
.shop {
  margin: 0 20px;
  border-top: 1px solid #e6e6e6;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-auto-rows: minmax(100px, auto);
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: 20px;
}
.shop-item {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.shop-item-name,
.shop-item-price {
  font-size: 0.75rem;
  font-weight: 600;
}
.shop-item-name {
  margin-top: 5px;
}
.shop a {
  text-decoration: none;
  color: inherit;
}
.subscribe-button .subscribe-text {
  margin-left: 1rem;
}
.subscribe-button svg {
  font-size: 1em;
  margin-right: 0.5rem;
}
.thank-you-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  text-align: center;
}

.product-item {
  position: relative;
  /* Add any other styling for your product item */
}

.pin-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: #888888;
  transform: rotate(30deg) scaleY(1.15);
  margin-right: 2rem;
}

.small-qr {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: -20px;
  cursor: pointer;
}
.big-qr {
  position: fixed;
  transition: all 0.3s;
  transform: translateY(-20px);
  pointer-events: none;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #0004;
  z-index: 100;
}
.big-qr > svg {
  position: absolute;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 70vh;
  height: 90vw;
  max-height: 70vh;
  background: white;
  padding: 50px;
  left: 50%;
  top: 50%;
}
.big-qr.open {
  pointer-events: all;
  opacity: 1;
  transform: translate(0px, 0px);
}
