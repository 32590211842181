.subscribe-button {
  width: fit-content; /* Set the desired width */
  height: 2.5rem; /* Set the same height as width for a perfect circle */
  border-radius: 0.7rem; /* Set border-radius to 50% to make it circular */
  background-color: white; /* Set the background color to white */
  color: #000000; /* Set the text color to a dark color */
  border: 1px solid #e6e6e6; /* Add a 2px solid border with dark color */
  cursor: pointer; /* Add a pointer cursor on hover */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  margin: 15px 15px 15px 30px;
  padding: 0rem 0.7rem;
  font-weight: bold;
}
