.shop-item {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.shop-item-image {
  width: 100%;
  height: auto;
}
.shop-item-name,
.shop-item-price {
  font-size: 0.75rem;
  font-weight: 600;
}
.shop-item-name {
  margin-top: 5px;
}
