.button {
  padding: 10px;
  border-radius: 10px;
  /* #eeeeef */
  background-color: #f5f5f5;
  display: flex;
  border: none;
  outline: none;
  /* width: 100%; */
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: black;
  transition: background-color 0.3s;
}
.button:active,
.button:focus,
.button:hover {
  background-color: #e9e8e8;
}
