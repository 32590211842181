.header {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25%;
  text-align: center;
  font-family: "Karla", sans-serif;
}
.header > h1 {
  font-size: 50px;
  font-family: "Contax W01 65 Medium";
  font-weight: 1000;
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  margin: 0;
}
ul.users {
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
  margin-bottom: 25vh;
}
li {
  text-align: left;
  padding: 10vh 0px;
  /* width: 400px; */
  width: 700px;
  text-align: center;
  font-size: 50px;
  font-weight: 1000;
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s, color 0.5s;
  cursor: pointer;
}
li > a {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-family: "Contax W01 65 Medium";
}
li.shown {
  opacity: 1;
  transform: translateY(0px);
}
li:hover {
  color: black;
}
p {
  margin-bottom: 0.02rem;
}
p.bigtext {
  font-size: 20px;
}
p.subtext {
  font-size: 19px;
}
a {
  text-decoration: none;
  color: inherit;
}
@media only screen and (max-width: 760px) {
  .header {
    padding: 10%;
  }
  .header > h1,
  li {
    font-size: 40px;
  }
}
